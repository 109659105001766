<template>
  <div class="job-future">
    <div class="box">
      <div class="box-title">就业方向</div>
      <div class="box-content">{{ specialtyInfo.Job }}</div>
    </div>
    <div v-if="specialtyInfo.SalaryList.length !== 0" class="box">
      <div class="box-title salary-title">
        <div class="left">近{{ specialtyInfo.SalaryList.length }}年平均薪资</div>
        <div class="right">{{ specialtyInfo.SalaryList[0].Year+'年 平均薪资 ￥'+ specialtyInfo.SalaryList[0].Salary+'元'}}</div>
      </div>
      <div class="box-content table-box">
        <div id="echartsLine"></div>
        <div class="salary">
          <div class="salary-content" v-for="(item, index) in salary" :key="index">
            <div class="salary-item" v-for="(subItem, subIndex) in item" :key="subItem.Year">
              <div class="year">{{ subItem.Year }}</div>
              <div class="money">￥{{ subItem.Salary }}元</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box box-distribute">
      <div class="box-item">
        <div class="box-title">主要职业分布</div>
        <div id="echartsPieProfession"></div>
      </div>
      <div class="box-item">
        <div class="box-title">主要行业分布</div>
        <div id="echartsPieIndustry"></div>
      </div>
      <div class="box-item">
        <div class="box-title">主要就业地区分布</div>
        <div id="echartsPieArea"></div>
      </div>
    </div>
    <div class="box box-distribute">
      <div class="box-item">
        <el-collapse accordion>
          <el-collapse-item v-for="(item, index) in specialtyInfo.DistributeProfessionList" :key="index">
            <template slot="title">
              <div class="collapse-title">
                <div>{{ item.PrefessionName }}</div>
<!--                <div class="percent">{{ toPercent(item.Proportion, professionTotal) }}</div>-->
                <div class="percent">{{ item.Proportion + '%' }}</div>
              </div>
            </template>
            <div class="collapse-content">{{ item.Jobs }}</div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="box-item">
        <div class="content-item" v-for="(item, index) in specialtyInfo.DistributeIndustryList" :key="index">
          <div class="left">{{ item.IndustryName }}</div>
<!--          <div class="right">{{ toPercent(item.Proportion, industryTotal) }}</div>-->
          <div class="right">{{ item.Proportion + '%' }}</div>
        </div>
      </div>
      <div class="box-item">
        <div class="content-item" v-for="(item, index) in specialtyInfo.DistributeAreaList" :key="index">
          <div class="left">{{ item.Area }}</div>
<!--          <div class="right">{{ toPercent(item.Proportion, areaTotal) }}</div>-->
          <div class="right">{{ item.Proportion + '%' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobFuture",
  data () {
    return {
      salary: [],

      // 为了计算百分比时使用的总数
      professionTotal: 0,
      industryTotal: 0,
      areaTotal: 0,
    }
  },
  props: {
    specialtyInfo: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    // 初始化数据
    initialize () {
      this.handleSalary()
      this.handleEchartsLine()
      this.distributeProfession()
      this.distributeIndustry()
      this.distributeArea()
    },
    // 分割平均薪资数据
    handleSalary () {
      let tempLength = this.specialtyInfo.SalaryList.length
      if (tempLength === 0) return
      this.$set(this.salary, 0, this.specialtyInfo.SalaryList.slice(0, tempLength / 2))
      this.$set(this.salary, 1, this.specialtyInfo.SalaryList.slice(tempLength / 2, tempLength))
    },
    // 薪资折线图
    handleEchartsLine () {

      if (this.specialtyInfo.SalaryList.length === 0) return
      let xArray = this.specialtyInfo.SalaryList.map(item => {
        return item.Year
      })
      let yArray = this.specialtyInfo.SalaryList.map(item => {
        return item.Salary
      })
      xArray = xArray.reverse()
      yArray = yArray.reverse()
      let OEcharts = this.$echarts.init(document.getElementById('echartsLine'))
      let options = {
        title: {
          text: '平均薪资',
          textStyle: {
            color: '#333',
            fontWeight: 'normal',
            fontSize: 16
          }
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,.4)',
          textStyle: {
            color: '#ffffff'
          }
        },
        xAxis: {
          type: 'category',
          data: xArray,
        },
        yAxis: {
          type: 'value'
        },
        series:[{
          type: 'line',
          data: yArray
        }]
      }
      OEcharts.setOption(options)
    },
    // 职业分布图（饼图）
    distributeProfession () {
      this.sortList(this.specialtyInfo.DistributeProfessionList, 'Proportion') // 数组排序

      let itemArray = this.specialtyInfo.DistributeProfessionList.map(item => {
        this.professionTotal += item.Proportion // 计算百分比的总数
        return { // echart配置使用
          value: item.Proportion,
          name: item.PrefessionName
        }
      })
      this.handlePieConfig('echartsPieProfession', itemArray)
    },
    // 行业分布图
    distributeIndustry () {
      this.sortList(this.specialtyInfo.DistributeIndustryList, 'Proportion') // 排序

      let itemArray = this.specialtyInfo.DistributeIndustryList.map(item => {
        this.industryTotal += item.Proportion
        return {
          value: item.Proportion,
          name: item.IndustryName
        }
      })
      this.handlePieConfig('echartsPieIndustry', itemArray)
    },
    // 地区分布图
    distributeArea () {
      this.sortList(this.specialtyInfo.DistributeAreaList, 'Proportion') // 排序

      let itemArray = this.specialtyInfo.DistributeAreaList.map(item => {
        this.areaTotal += item.Proportion
        return {
          value: item.Proportion,
          name: item.Area
        }
      })
      this.handlePieConfig('echartsPieArea', itemArray)
    },
    // 数组公用排序处理
    sortList (array, property) {
      // array 需要排序的数组，property 元素中排序依据的属性
      for(let i = 0; i < array.length; i++) {
        for(let j = 0; j < array.length - i - 1; j++) {
          if(array[j][property] < array[j + 1][property]) {
            let temp = array[j]
            array[j] = array[j + 1]
            array[j + 1] = temp
          }
        }
      }
    },
    // 饼图配置公共部分
    handlePieConfig (domID, data) {
      let OEcharts = this.$echarts.init(document.getElementById(domID))
      let options = {
        legend: {
          type: 'plain',
          // orient: 'horizontal',
          icon: 'circle',
          left: 0
        },
        tooltip: {
          show: true,
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,.4)',
          textStyle: {
            color: '#ffffff'
          },
          formatter(parameter, ticket, callback){
            return parameter.data.name + '<br />' + '占比：' + parameter.data.value +'%'
          }
        },
        series: {
          type: 'pie',
          radius: ['15%', '35%'],
          selectedMode: 'single',
          selectedOffset: 15,
          label: {
            position: 'outer',
            alignTo: 'edgeLabel',
            bleedMargin: 0
          },
          data: data
        }
      }
      OEcharts.setOption(options)
    },
    // 百分比转换
    toPercent(num, total) {
      let temp = (num / total) * 100
      return Math.round(temp) + '%'
    }
  }
}
</script>

<style scoped lang="less">
.job-future {
  padding: 3rem 0;
  .box {
    margin-bottom: 40px;
  }
  .box-distribute {
    display: flex;
    justify-content: space-between;
    .box-item {
      flex-shrink: 0;
      width: 350px;
      #echartsPieProfession, #echartsPieIndustry, #echartsPieArea {
        width: 350px;
        height: 300px;
      }

      .collapse-title {
        width: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;

        .percent {
          //margin: 0 20px;
          color: red;
        }
      }
      .collapse-content {
        //width: 350px;
        padding: 10px;
      }

      .content-item {
        padding: 11px;
        border-bottom: 1px solid var(--color-grey);
        font-size: 13px;

        display: flex;
        justify-content: space-between;
        .left {

        }
        .right {
          color: red;
        }
      }


    }
  }

  .box-title {
    padding-left: .3rem;
    margin: 20px 0;
    border-left: .3rem solid rgba(var(--themecolor),0.75);
  }
  .salary-title {
    display: flex;
    .right {
      margin-left: 180px;
      color: red;
    }
  }
  .box-content {
    line-height: 1.5;
    text-indent: 2em;
    font-size: var(--text-font-size);
    color: var(--text-font-color);
  }
  .table-box {
    display: flex;
    #echartsLine {
      width: 500px;
      height: 300px;
      padding: 20px;
      border: 1px solid var(--color-red);
    }
    .salary {
      margin-left: 10px;
      display: flex;
      .salary-content {
        margin-right: 20px;
      }
      .salary-item {
        width: 300px;
        padding-bottom: 10px;
        margin-bottom: 30px;
        border-bottom: 1px solid var(--color-grey);
        display: flex;
        justify-content: space-between;
        font-size: var(--text-font-size);
        .money {
          color: red;
        }
      }
    }
  }
}

</style>
