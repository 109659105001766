<template>
  <div class="app-container">
    <div class="top">
      <div class="top-name">
        <h2>[{{ mainData.EducationLevel }}]{{ mainData.SpecialtyName }}</h2>
      </div>
      <div class="top-info">
        <div class="top-info-item">
          <span class="name">专业代码：</span>
          <span class="value">{{ mainData.SpecialtyCode }}</span>
        </div>
        <div class="top-info-item">
          <span class="name">专业分类：</span>
          <span class="value">{{ mainData.CategoryName }}</span>
        </div>
        <div class="top-info-item">
          <span class="name">授予学位：</span>
          <span class="value">{{ mainData.Degree }}</span>
        </div>
        <div v-if="mainData.PercentOfMale || mainData.PercentOfMale === 0" class="top-info-item">
          <span class="name">男女比例：</span>
          <span class="value">{{ '男：' + mainData.PercentOfMale + '% | ' + '女：' + mainData.PercentOfFemale + '%' }}</span>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div v-if="mainData.VideoUrl" id="sepcialty_detail_video" oncontextmenu="return false">
        <div class="video-title">专业新说</div>
        <video class="video-box" :src="mainData.VideoUrl" controls="controls" controlsList="nodownload"></video>
      </div>

      <div class="title">
        <div :class="['bottom-title', {'activate-title': selectedIndex === 1}]" @click="selectItem(1)">专业概况</div>
        <div :class="['bottom-title', {'activate-title': selectedIndex === 2}]" @click="selectItem(2)">就业前景</div>
        <div :class="['bottom-title', {'activate-title': selectedIndex === 3}]" @click="selectItem(3)">开设院校</div>
      </div>

      <div v-show="selectedIndex === 1" class="info">
        <div class="bottom-item" v-if="mainData.SpecialtyIntroduce">
          <div class="bottom-item-name">专业介绍：</div>
          <p class="bottom-item-content">{{ mainData.SpecialtyIntroduce }}</p>
        </div>
        <div class="bottom-item" v-if="mainData.DevlopTarget">
          <div class="bottom-item-name">培养目标：</div>
          <p class="bottom-item-content">{{ mainData.DevlopTarget }}</p>
        </div>
        <div class="bottom-item" v-if="mainData.Course">
          <div class="bottom-item-name">主要课程：</div>
          <p class="bottom-item-content">{{ mainData.Course }}</p>
        </div>
        <div class="bottom-item" v-if="mainData.Ability">
          <div class="bottom-item-name">具备能力：</div>
          <p class="bottom-item-content" v-html="mainData.Ability"></p>
        </div>
<!--        <div class="bottom-item" v-if="mainData.Job">-->
<!--          <div class="bottom-item-name">就业方向：</div>-->
<!--          <p class="bottom-item-content">{{ mainData.Job }}</p>-->
<!--        </div>-->
        <div class="bottom-item" v-if="mainData.Ability">
          <div class="bottom-item-name">{{ mainData.EducationLevel === '本科'?'考研方向':'专升本方向' }}：</div>
          <p class="bottom-item-content">{{ mainData.NextEducationLevel }}</p>
        </div>
        <div class="bottom-item" v-if="mainData.ProfessionList&&mainData.ProfessionList.length > 0">
          <div class="bottom-item-name">推荐职业：</div>
          <div class="bottom-item-content">
            <div class="bottom-item-content-recommend" v-for="(item, index) in mainData.ProfessionList" :key="index">
              <span class="recommend-item"
                    @click="openProfessionDetail(item)">{{ index + 1 }}、{{ item.ProfessionName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-show="selectedIndex === 2" class="job">
        <job-future v-if="Object.keys(mainData).length > 0" :specialty-info="mainData"></job-future>
      </div>
      <div v-show="selectedIndex === 3" class="college">
        <establish-college :selected-index="selectedIndex"></establish-college>
      </div>
    </div>
  </div>
</template>

<script>
// import {SpecialtyInfo} from '@/api/search_specialty'
import API from '@/api/config'
import JobFuture from "@/views/tool/specialty/JobFuture";
import EstablishCollege from "@/views/tool/specialty/EstablishCollege";

export default {
  components: {
    JobFuture,
    EstablishCollege
  },
  name: "Secialty-Detail",
  data () {
    return {
      mainData: {},

      selectedIndex: 1
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    // 选择专业对应项
    selectItem (num) {
      switch (num) {
        case 1: // 专业概况
          this.selectedIndex = 1
          break
        case 2: // 就业前景
          this.selectedIndex = 2
          break
        default: // 开设院校
          this.selectedIndex = 3

      }
    },
    //打开对应职业详情
    openProfessionDetail (item) {
      this.$router.push({
        path: '/profession-detail',
        query: {
          ID: item.ProfessionId
        }
      })
    },
    //初始化数据
    initialize () {
      // 加载
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,.6)'
      })
      API.Specialty.SpecialtyInfo({SpecialtyCode: this.$route.query.SpecialtyCode}).then(res => {
        this.mainData = res.data
        this.mainData.Ability = this.mainData.Ability.replace(/\n/g, '<br>')
        loading.close()
      }).catch(err => {
        this.$messageTips('error', err.message)
        loading.close()
      })
    }
  }
}
</script>

<style scoped lang="less">
.top {
  padding-top: 2rem;

  &-name {
    font-size: var(--title-font-size);
  }

  &-info {
    padding: 2rem 0;
    display: flex;
    flex-wrap: wrap;

    &-item {
      width: 45%;
      margin-bottom: .5rem;

      .name {
        font-size: var(--text-font-color);
        font-weight: 700;
      }

      .value {
        font-size: var(--text-font-size);
        color: var(--text-font-color);
      }
    }
  }
}

.bottom {
  min-height: 80vh;
  padding-bottom: 2rem;

  .title {
    display: flex;
    border-bottom: 1px solid rgba(var(--themecolor),0.75);

    .bottom-title {
      padding: .5rem 1rem;
      margin-right: 1rem;
      border-top-left-radius: .3rem;
      border-top-right-radius: .3rem;
      font-size: var(--title-font-size);
      color: rgba(var(--themecolor),0.75);
      cursor: pointer;
      user-select: none;
      transition: color .1s linear, background-color .1s linear;
    }

    .activate-title {
      color: #ffffff;
      background-color: rgba(var(--themecolor),0.75);
    }
  }


  &-item {
    margin-top: 2.5rem;

    &-name {
      font-size: var(--text-font-size);
      font-weight: 700;
      color: var(--text-font-color);
    }

    &-content {
      line-height: 1.5;
      margin-top: .5rem;
      //text-indent: 2em;
      font-size: var(--text-font-size);
      color: var(--text-font-color);

      &-recommend {
        margin-bottom: .5rem;

        .recommend-item {
          color: rgba(var(--themecolor),0.75);
          cursor: pointer;

          &:hover {
            border-bottom: 1px solid rgba(var(--themecolor),0.75);
          }
        }
      }
    }
  }

  .video-title {
    font-size: var(--title-font-size);
    padding-left: .8rem;
    border-left: 4px solid rgba(var(--themecolor),0.75);
    color: rgba(var(--themecolor),1);
  }

  .video-box {
    width: 100%;
    height: 22rem;
  }
}
</style>
<style lang="less">

</style>