<template>
  <div class="screen-container">
    <div class="content">
      <div class="content-title">
        院校地区：
      </div>
      <div :class="['no-limit', {activate: query.SchoolProvinceIdList.length === 0}]"
           @click="clearSelected('province')">不限
      </div>
      <el-checkbox-group class="content-item" v-model="query.SchoolProvinceIdList" size="mini">
        <el-checkbox-button v-for="(item, index) in mainData.ProvinceList" :label="item.ID" :key="index">
          {{ item.Name }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="content">
      <div class="content-title">
        院校类别：
      </div>
      <div :class="['no-limit', {activate: query.SchoolCategoryIdList.length === 0}]"
           @click="clearSelected('category')">不限
      </div>
      <el-checkbox-group class="content-item" v-model="query.SchoolCategoryIdList" size="mini">
        <el-checkbox-button v-for="(item, index) in mainData.SchoolCategoryList" :label="item.ID" :key="index">
          {{ item.CategoryName }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    
    <div class="content">
      <div class="content-title">
        特别属性：
      </div>
      <div :class="['no-limit', {activate: query.SchoolTagList.length === 0}]" @click="clearSelected('tag')">不限</div>
      <el-checkbox-group class="content-item" v-model="query.SchoolTagList" size="mini">
        <el-checkbox-button v-for="(item, index) in mainData.SchoolTagList" :label="item.Value" :key="index">
          {{ item.Name }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="content">
      <div class="content-title">
        院校名称：
      </div>
      <el-input v-model="query.SchoolName" placeholder="请输入院校名称" clearable></el-input>
    </div>
    <div class="submit">
      <el-button class="submit-button" @click="submitScreen" type="primary" plain size="small"
                 :loading="screenButtonLoading">{{ screenButtonLoading ? '搜索中...' : '确认筛选' }}
      </el-button>
    </div>
  </div>
</template>

<script>
// import {BaseCondition, ProvinceBatchList} from '@/api/common'
import API from '@/api/config'

export default {
  name: "ConditionScreen",
  props: {
    conditionConfig: Array
  },
  data () {
    return {
      mainData: {},
      screenButtonLoading: false,
      query: {
        SpecialtyCode: this.$route.query.SpecialtyCode,
        SchoolProvinceIdList: [],
        SchoolCategoryIdList: [],
        SchoolTagList: [],
        SchoolName: "",
        PageIndex: 1,
        PageSize: 10
      },
      isNewExam: false,
      newExamActive: false,
      ruleFormLs: {},
      ruleForm: {
        ProvinceId: "",
        ProvinceIdName: "",
        Year: "",
        SubjectType: '1',
        SelectSubject: "物理",
        BatchId: "",
        BatchName: "",
        Score: "",
      },
      
      BatchList: [],
      tagText: "",
    }
  },
  mounted () {
    this.initialize()
    this.$globalEventBus.$on('screenButtonLoading', data => {
      this.screenButtonLoading = data
    })
  },
  methods: {
    // 点击不限时，清空该类的其他已选项
    clearSelected (type) {
      switch (type) {
        case 'province':
          this.query.SchoolProvinceIdList = []
          break
        case 'category':
          this.query.SchoolCategoryIdList = []
          break
        case 'tag':
          this.query.SchoolTagList = []
      }
    },

    handleClose () {
      this.tagText = "";
      this.query.SchoolHisotyScore = undefined;
      this.ruleForm = {
        ProvinceId: "",
        ProvinceIdName: "",
        Year: "",
        SubjectType: 1,
        SelectSubject: "物理",
        BatchId: "",
        BatchName: "",
        Score: "",
      };
      this.$ls.remove("ruleFormLs");
      this.submitScreen();
    },
    
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const SchoolHisotyScore = {
            ProvinceId: this.ruleForm.ProvinceId,
            Year: Number(this.ruleForm.Year),
            SubjectType: Number(this.ruleForm.SubjectType),
            SelectSubject: this.ruleForm.SelectSubject,
            BatchId: this.ruleForm.BatchId,
            // BatchId: "2dd72669-63b9-497e-9d6f-6417dd9cb27d",
            Score: this.ruleForm.Score,
          };
          this.$ls.set('ruleFormLs', this.ruleForm);
          this.ruleFormLs = this.$ls.get('ruleFormLs');
          this.tagText =
            this.ruleFormLs.ProvinceIdName +
            "+" +
            this.ruleFormLs.Year +
            "年+录取分数低于" +
            this.ruleFormLs.Score +
            "分";
          this.query.SchoolHisotyScore = SchoolHisotyScore;
          // this.currentPage = 1;
          this.submitScreen();
        } else {
          this.$message("请检查分数查询板块必填项");
        }
      });
    },
    
    //提交筛选参数
    submitScreen () {
      this.screenButtonLoading = true
      this.$emit('screenParameters', this.query, () => {
        this.screenButtonLoading = false
      })
    },
    //初始化数据
    initialize () {
      this.getScreenList()
    },
    async getScreenList () {
      this.mainData = (await API.School.BaseCondition()).data
      this.mainData.educationLevel = [
        {Name: '本科', ID: 1},
        {Name: '高职高专', ID: 0}
      ]
    }
  }
}
</script>

<style scoped lang="less">
.screen-container {
  padding: 2rem;
  border: 1px solid var(--color-grey);
  background-color: #fff;

  .content {
    margin-bottom: 1rem;
    display: flex;

    &-title {
      width: 10%;
      font-size: var(--text-font-size);
      margin-right: 3rem;
    }

    .no-limit {
      box-sizing: border-box;
      width: 68px;
      height: 24px;
      padding: 4px 12px;
      margin-right: 10px;
      border: 1px solid #e3e3e3;
      color: #606266;
      border-radius: 16px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
    }
    .activate {
      background-color: rgba(var(--themecolor),0.75);
      color: #ffffff;
    }

    &-item {
      width: 90%;

      ::v-deep .el-checkbox-button {
        margin: 0 1rem .5rem 0;

        .el-checkbox-button__inner {
          padding: .3rem 1.2rem;
          border: 1px solid var(--color-grey);
          border-radius: 1rem;
        }

        .el-checkbox-button__orig-checkbox:checked + .el-checkbox-button__inner {
          box-shadow: none;
        }

      }

      ::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
        box-shadow: none;
      }
    }
  }

  .submit {
    text-align: center;

    &-button {
      width: 6rem;
    }
  }
}
</style>
<style lang="less">
.el-checkbox-button__inner:hover{
  color: rgba(var(--themecolor),0.75);
}
.el-checkbox-button.is-checked .el-checkbox-button__inner{
  background-color: rgba(var(--themecolor),0.75);
}
.el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select .el-input__inner:focus,.el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-button--primary.is-plain{
  border-color: rgba(var(--themecolor),0.75);
  color: rgba(var(--themecolor),1);
}
.el-button--primary.is-plain:focus, .el-button--primary.is-plain:hover{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
</style>